import CurrentUserModel from "@/models/current-user.model";
import DirectClientModel from "@/models/direct-client.model";
import { injectable } from "inversify-props";

let currentuserModel: CurrentUserModel | null = null;

@injectable()
export default class SessionService {
  setCurrentUser(user: CurrentUserModel): CurrentUserModel {
    currentuserModel = user;
    localStorage.setItem("directClientUser", JSON.stringify(user));

    return user;
  }

  logoutCurrentUser(): void {
    currentuserModel = null;
    localStorage.removeItem("directClientUser");
  }

  getCurrentUser(): CurrentUserModel | null {
    if (currentuserModel) {
      return currentuserModel;
    }

    const stringUser = localStorage.getItem("directClientUser");
    if (stringUser) {
      const data: CurrentUserModel = JSON.parse(stringUser);
      currentuserModel = data;

      return currentuserModel;
    }

    return null;
  }

  getCurrentUserData(): DirectClientModel | null {
    const user: CurrentUserModel | null = this.getCurrentUser();
    if (user && user.user) {
      return user.user;
    }

    return null;
  }

  get apiToken(): string {
    const user: CurrentUserModel | null = this.getCurrentUser();
    if (user && user.token) {
      return user.token;
    }

    return "";
  }
}
