import { Vue } from "vue-property-decorator";

type VueComponent = Vue & {
  validate: () => boolean;
  resetValidation: () => void;
  reset: () => void;
};

export abstract class FormHelper {
  static validate(form: Vue, bubbleError = true): boolean {
    const formComponent = form as VueComponent;
    const validate = formComponent.validate();

    if (!validate && bubbleError) {
      Vue.prototype.$notify.error("Você tem erros no formulário.");
    }

    return validate;
  }

  static resetValidation(form: Vue): void {
    const formComponent = form as VueComponent;
    formComponent.resetValidation();
  }

  static reset(form: Vue): void {
    const formComponent = form as VueComponent;
    formComponent.reset();
  }
}
