import { inject } from "inversify-props";
import HttpService from "@/services/core/http.service";
import SessionService from "@/services/core/session.service";
import { InjectionIdEnum } from "@/enums/core/injection-id.enum";
import getConfig from "@/env-config";

export default abstract class BaseService {
  @inject(InjectionIdEnum.HttpService)
  protected httpService!: HttpService;

  @inject(InjectionIdEnum.SessionService)
  protected sessionService!: SessionService;

  getBaseImg(): string {
    return getConfig().baseUrlImg;
  }

  getApiPath(): string {
    return getConfig().baseUrlApi;
  }

  getApiToken(): string {
    const { apiToken } = this.sessionService;

    if (!apiToken) {
      throw new Error("Api token not defined");
    }

    return apiToken;
  }
}
