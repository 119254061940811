import DirectProductModel from "./direct-product.model";
import ImageModel from "./image.model";

export default class CartItemModel {
  id!: string;

  directProductId!: string;

  name!: string;

  minQuantity!: number;

  quantity!: number;

  stock!: number;

  amount!: number;

  promotion!: number;

  description!: string;

  active!: boolean;

  images!: ImageModel[];

  quantityTotal: number = 0;

  kitBuy: number = 0;

  total: number = 0;

  amountPaid?: number;

  constructor(item: DirectProductModel) {
    this.directProductId = item.id;
    this.name = item.name;
    this.minQuantity = item.minQuantity;
    this.amount = item.amount;
    this.promotion = item.promotion;
    this.description = item.description;
    this.images = item.images;
    this.quantity = item.kitBuy;
    this.kitBuy = item.kitBuy;
    this.quantityTotal = item.quantityTotal;
    this.total = item.total;
  }
}
