import CartItemModel from "./cart-item.model";

export default class CartModel {
  id?: string;
  createdAt?: Date;
  status?: string;
  items: CartItemModel[] = [];
  paymentMethod: string = "";
  discount: number = 0;
  itensTotal: number = 0;
  subtotal: number = 0;
  total: number = 0;
}
