import { NotificationTypeEnum } from '@/enums/core/notification-type.enum';

export default class NotificationModel {
  active = true;

  text!: string | unknown;

  type!: NotificationTypeEnum;

  timeout!: number;

  closeable!: boolean;

  constructor(text: string | unknown, type = NotificationTypeEnum.Error, timeout = 3500, closeable = true) {
    this.text = text;
    this.type = type;
    this.timeout = timeout;
    this.closeable = closeable;
  }
}
