import CartModel from '@/models/cart.model';
import DirectProductModel from '@/models/direct-product.model';
import { VueConstructor } from 'vue';
import { Store } from 'vuex';

declare module 'vue/types/vue' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Vue {
    readonly $cart: IPluginApi;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface VueConstructor {
    readonly $cart: IPluginApi;
  }
}

export interface IPluginApi {
  add(product?: DirectProductModel): void;
  remove(product?: DirectProductModel): void;
  clean(): void;
  get(): CartModel;
  getCartItensNumber(): Number;
}

const plugin = {
  install: (vue: VueConstructor, store: Store<unknown>): void => {
    if (!store) {
      throw new Error('Please provide vuex store.');
    }

    const vueInstance = vue;
    vueInstance.prototype.$cart = {
      add: (product: DirectProductModel) => {
        store.commit('addProduct', product, { root: true });
      },
      remove: (product: DirectProductModel) => {
        store.commit('removeProduct', product, { root: true });
      },
      clean: () => {
        store.commit('cleanCart');
      },
      get: (): CartModel => store.getters.cart,
      getCartItensNumber: (): Number => store.getters.getCartItensNumber
    };
  },
};

export default plugin;
