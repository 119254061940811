import Vue from "vue";
import Vuex from "vuex";
import NotificationModel from "@/models/core/notification.model";
import CartModel from "@/models/cart.model";
import DirectProductModel from "@/models/direct-product.model";
import CartItemModel from "@/models/cart-item.model";

Vue.use(Vuex);

class State {
  notifications: NotificationModel[] = [];
  cart: CartModel = new CartModel();
  readCache = false;
}

export default new Vuex.Store({
  state: new State(),
  mutations: {
    addNotification: (state, payload: NotificationModel, distinct = true) => {
      if (distinct) {
        state.notifications = state.notifications.filter(
          (item) => item.text !== payload.text
        );
      }

      state.notifications = [...state.notifications, payload];
    },

    addProduct: (state, payload: DirectProductModel) => {
      const index = state.cart.items.findIndex(
        (x) => x.directProductId === payload.id
      );
      if (index >= 0) {
        state.cart.items[index].quantityTotal = payload.quantityTotal;
        state.cart.items[index].kitBuy = payload.kitBuy;
      } else {
        const newItem = new CartItemModel(payload);
        state.cart.items.push(newItem);
      }

      localStorage.setItem("directCart", JSON.stringify(state.cart));
    },
    removeProduct: (state, payload: CartItemModel) => {
      const index = state.cart.items.findIndex(
        (x) => x.directProductId === payload.directProductId
      );
      if (index >= 0) {
        state.cart.items.splice(index, 1);

        localStorage.setItem("directCart", JSON.stringify(state.cart));
      }
    },
    cleanCart: (state) => {
      state.cart = new CartModel();
      localStorage.setItem("directCart", JSON.stringify(state.cart));
    },
  },
  getters: {
    notifications: (state) => state.notifications.filter((item) => item.active),
    cart: (state) => state.cart,
    getCartItensNumber: (state) => {
      if (!state.readCache && state.cart.items.length <= 0) {
        const cartJson = localStorage.getItem("directCart");
        if (cartJson) {
          state.cart = JSON.parse(cartJson);
        }

        state.readCache = true;
      }

      if (state.cart.items.length > 0) {
        state.cart.items.forEach((x) => {
          x.total = x.kitBuy * Number(x.promotion > 0 ? x.promotion : x.amount);
        });

        state.cart.discount = 0;
        state.cart.total = state.cart.subtotal = state.cart.items
          .map((it) => Number(it.total))
          .reduce((a, b) => a + b);
      } else {
        state.cart.discount = 0;
        state.cart.itensTotal = 0;
        state.cart.subtotal = 0;
        state.cart.total = 0;
      }

      return state.cart.items.length;
    },
  },
});
