import "reflect-metadata";
import Vue from "vue";
import axios from "axios";
import VComp from "@vue/composition-api";
import App from "@/app.vue";
import "@/registerServiceWorker";
import router from "@/router";
import store from "@/store/store";
import "@/plugins/loader";
import "@/plugins/mask";
import "@/plugins/dayjs";
import vuetify from "@/plugins/vuetify";
import notifier from "@/plugins/notifier";
import cart from "@/plugins/cart";
import buildDependencyContainer from "@/app.container";

buildDependencyContainer();

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

Vue.use(VComp);
Vue.use(cart, store);
Vue.use(notifier, store);

Vue.filter("toCurrency", function (value) {
  if (typeof value !== "number") {
    value = Number(value);
  }
  const options = {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  const formatNumber = new Intl.NumberFormat("pt-BR", options);

  return formatNumber.format(value);
});

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
