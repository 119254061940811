import ImageModel from "./image.model";

export default class DirectProductModel {
  id!: string;

  name!: string;

  minQuantity!: number;

  quantity!: number;

  stock!: number;

  amount!: number;

  promotion!: number;

  description!: string;

  active!: boolean;

  images!: ImageModel[];

  quantityTotal: number = 0;

  kitBuy: number = 0;

  total: number = 0;
}
