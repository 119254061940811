
import { Component, Vue } from "vue-property-decorator";
import Login from "@/components/Login.vue"; // @ is an alias to /src

@Component({
  components: {
    Login,
  },
})
export default class LoginView extends Vue {
  deferredPrompt: any = null;

  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  }

  async dismiss() {
    this.deferredPrompt = null;
  }

  async install() {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();
    }
  }
}
