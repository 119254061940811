import { injectable } from "inversify-props";
import BaseService from "./core/base-service";
import CartModel from "@/models/cart.model";

@injectable()
export default class DirectOrderService extends BaseService {
  async create(cart: CartModel): Promise<any> {
    return (await this.httpService.post(
      CartModel,
      `${this.getApiPath()}/v1/direct-order`,
      cart
    )) as any;
  }

  async cancel(id: string): Promise<any> {
    return (await this.httpService.post(
      CartModel,
      `${this.getApiPath()}/v1/direct-order/cancel/${id}`,
      null
    )) as any;
  }

  async getAll(): Promise<CartModel[]> {
    return (await this.httpService.get(
      CartModel,
      `${this.getApiPath()}/v1/direct-order`
    )) as CartModel[];
  }
}
