import { VueConstructor } from 'vue';
import { Store } from 'vuex';
import NotificationModel from '@/models/core/notification.model';
import { NotificationTypeEnum } from '@/enums/core/notification-type.enum';

declare module 'vue/types/vue' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Vue {
    readonly $notify: IPluginApi;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface VueConstructor {
    readonly $notify: IPluginApi;
  }
}

export interface IPluginApi {
  show(notification?: NotificationModel): void;
  error(text?: string | unknown, timeout?: number): void;
  success(text?: string | unknown, timeout?: number): void;
  warn(text?: string | unknown, timeout?: number): void;
  info(text?: string | unknown, timeout?: number): void;
  notifications(): NotificationModel[];
}

const plugin = {
  install: (vue: VueConstructor, store: Store<unknown>): void => {
    if (!store) {
      throw new Error('Please provide vuex store.');
    }

    const vueInstance = vue;
    vueInstance.prototype.$notify = {
      show: (notification: NotificationModel) => {
        store.commit('addNotification', notification, { root: true });
      },
      error: (text: string | unknown, timeout?: number) => {
        store.commit('addNotification', new NotificationModel(text, NotificationTypeEnum.Error, timeout), {
          root: true,
        });
      },
      success: (text: string | unknown, timeout?: number) => {
        store.commit('addNotification', new NotificationModel(text, NotificationTypeEnum.Success, timeout), {
          root: true,
        });
      },
      warn: (text: string | unknown, timeout?: number) => {
        store.commit('addNotification', new NotificationModel(text, NotificationTypeEnum.Warn, timeout), {
          root: true,
        });
      },
      info: (text: string | unknown, timeout?: number) => {
        store.commit('addNotification', new NotificationModel(text, NotificationTypeEnum.Info, timeout), {
          root: true,
        });
      },
      notifications: (): NotificationModel[] => store.getters.notifications,
    };
  },
};

export default plugin;
