import { injectable } from "inversify-props";
import BaseService from "./core/base-service";
import DirectProductModel from "@/models/direct-product.model";

@injectable()
export default class DirectProductService extends BaseService {
  async getAll(): Promise<DirectProductModel[]> {
    let data = (await this.httpService.get(
      DirectProductModel,
      `${this.getApiPath()}/v1/direct-products/client`
    )) as any;

    return data.results;
  }

  async query(productIds: string[]): Promise<DirectProductModel[]> {
    const where = JSON.stringify([[{ "col": "id", "op": "in", "value": productIds }]]);
    
    let data = (await this.httpService.get(
      DirectProductModel,
      `${this.getApiPath()}/v1/direct-products?includeImages=true&where=${where}`
    )) as any;

    return data.results;
  }
}
