
import { Vue, Component, Prop } from "vue-property-decorator";
import { isArray } from "lodash";

@Component({})
export default class Field extends Vue {
  @Prop()
  readonly label!: string;

  @Prop()
  readonly rules!: (CallableFunction | unknown[])[];

  @Prop({ default: "auto" })
  readonly width!: string | number;

  get parsedRules(): unknown {
    return (this.rules || []).map((ruleParams) => {
      if (isArray(ruleParams)) {
        const rule = (ruleParams as []).shift() as unknown as CallableFunction;
        return (v: unknown) => rule(v, this.label, ...(ruleParams as []));
      }

      if (typeof ruleParams === "function") {
        return (v: unknown) => ruleParams(v, this.label);
      }

      return ruleParams;
    });
  }

  get widthStyle(): string {
    if (typeof this.width === "number") {
      return `${this.width}px`;
    }

    return this.width;
  }
}
