import { injectable } from "inversify-props";
import BaseService from "./core/base-service";
import CredentialsModel from "@/models/credentials.model";
import CurrentUserModel from "@/models/current-user.model";

@injectable()
export default class AuthenticationService extends BaseService {
  async login(credentials: CredentialsModel): Promise<CurrentUserModel> {
    return (await this.httpService.post(
      CurrentUserModel,
      `${this.getApiPath()}/v1/auth/direct-login`,
      credentials
    )) as CurrentUserModel;
  }
}
