interface EnvConfig {
  baseUrlApi: string;
  baseUrlImg: string;
}

const getConfig = (): EnvConfig => ({
  baseUrlApi: process.env.VUE_APP_BASE_URL_API || "",
  baseUrlImg: process.env.VUE_APP_BASE_IMG_URL || "",
});

export default getConfig;
