import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "../views/LoginView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: LoginView,
  },
  {
    path: "/home",
    name: "home",
    redirect: "/home/initial",
    component: () => import("../views/MainView.vue"),
    children: [
      {
        path: "initial",
        name: "initial",
        component: () => import("../views/InitialView.vue"),
      },
      {
        path: "cart",
        name: "cart",
        component: () => import("../views/CartView.vue"),
      },
      {
        path: "account",
        name: "account",
        component: () => import("../views/AccountView.vue"),
      },
      {
        path: "orders",
        name: "orders",
        component: () => import("../views/OrderView.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/", "/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("directClientUser");

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/");
  } else {
    if (publicPages.includes(to.path) && loggedIn) {
      next("/home/initial");
    } else {
      next();
    }
  }
});

export default router;
