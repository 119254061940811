import { container } from "inversify-props";
import SessionService from "@/services/core/session.service";
import HttpService from "@/services/core/http.service";
import { InjectionIdEnum } from "./enums/core/injection-id.enum";
import AuthenticationService from "./services/authentication.service";
import DirectProductService from "./services/direct-product.service";
import DirectOrderService from "./services/direct-order.service";

export default function buildDependencyContainer(): void {
  container.addSingleton(SessionService, InjectionIdEnum.SessionService);
  container.addSingleton(HttpService, InjectionIdEnum.HttpService);
  container.addSingleton(
    AuthenticationService,
    InjectionIdEnum.AuthenticationService
  );
  container.addSingleton(
    DirectProductService,
    InjectionIdEnum.DirectProductService
  );
  container.addSingleton(
    DirectOrderService,
    InjectionIdEnum.DirectOrderService
  );
}
