
import { ValidationRules } from "@/utils/helpers/validation-rules-helper";
import { Component, Vue } from "vue-property-decorator";
import { inject } from 'inversify-props';
import Field from "./core/field.vue";
import { FormHelper } from "@/utils/helpers/form-helper";
import CredentialsModel from "@/models/credentials.model";
import AuthenticationService from "@/services/authentication.service";
import { InjectionIdEnum } from "@/enums/core/injection-id.enum";
import SessionService from "@/services/core/session.service";
import CurrentUserModel from "@/models/current-user.model";

@Component({
  components: {
    Field,
  },
})
export default class Login extends Vue {
  @inject(InjectionIdEnum.SessionService)
  private sessionService!: SessionService;

  @inject(InjectionIdEnum.AuthenticationService)
  private authenticationService!: AuthenticationService;

  rules = {
    document: [ValidationRules.required, ValidationRules.cnpj],
    password: [ValidationRules.required],
  };

  formIsValid = true;

  loginData: CredentialsModel = new CredentialsModel();

  async onTry() {
    const load = this.$loading.show();
    try {
      if (FormHelper.validate(this.$refs.form as Vue)) {
        const dataLogin: CurrentUserModel = await this.authenticationService.login(this.loginData);
        this.sessionService.setCurrentUser(dataLogin);
        this.$router.replace('/home');
        window.location.reload();
      }
    } catch (e){
      this.$notify.warn('Email e/ou senha inválidos.');
    } finally {
      load.hide();
    }
  }
}
